body {
  margin: 0;
  background-color: #000;
  color: #fff;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes blink-animation {
  0%,
  50% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
